const Content = styled.main(`
  mx-auto
  px-4
  pt-6
  max-w-4xl
  w-full
  relative
  grow-1

  md:px-6
  lg:pt-8
  main
`)


type MainProps = {
  children: React.ReactNode
  className?: string
}

export default function Main(props: MainProps) {
  const {
    children,
    className,
  } = props

  return (
    <Content className={className}>
      {children}
    </Content>
  )
}
